import { createSlice } from '@reduxjs/toolkit'
import { findLastIndex } from 'lodash'
import { v4 as uuid } from 'uuid'

const initialState: SelectedFurnitureState = {
  value: [],
}

export const selectedFurnitureSlice = createSlice({
  name: 'selectedFurniture',
  initialState,
  reducers: {
    addFurniture: (state, action) => {
      const furnitureToUpdate = {
        actions: {
          attention: [],
          disassembly: {
            toDisassemble: false,
            toReassemble: false,
            complexity: null,
          },
          leaveIt: null,
          protection: null,
          comment: '',
        },
        variants: action.payload.furniture.variants,
        furnitureItem: action.payload.furniture,
        id: uuid(), // only for adding furniture, allows updateById necessary for furnitureActions
        room: action.payload.room,
      }
      state.value = [...state.value, furnitureToUpdate]
    },
    updateFurniture: (state, action) => {
      const furnitureToUpdate = {
        actions: action.payload.actions,
        variants: action.payload.variants,
        furnitureItem: action.payload.furniture,
        id: uuid(), // only for adding furniture, allows updsateById necessary for furnitureActions
        room: action.payload.room,
      }
      const itemToUpdate = state.value.findIndex(
        (item) =>
          item.furnitureItem.label === furnitureToUpdate.furnitureItem.label &&
          item.furnitureItem.key === furnitureToUpdate.furnitureItem.key
      )

      if (itemToUpdate !== -1) {
        state.value[itemToUpdate] = furnitureToUpdate
      }
    },
    removeFurniture: (state, action) => {
      const newSelectedFurniture = [...state.value]
      const furnitureToRemove = findLastIndex(
        newSelectedFurniture,
        (item: any) =>
          item.furnitureItem.label === action.payload.furniture.label &&
          item.room.id === action.payload.room.id
      )
      newSelectedFurniture.splice(furnitureToRemove, 1)
      state.value = newSelectedFurniture
    },
    updateSelectedFurniture: (state, action) => {
      // TODO
      // add more actions here when more furnitureActions are ready
      const furnitureToUpdate = {
        actions: {
          attention: [],
          disassembly: {
            toDisassemble: false,
            toReassemble: false,
            complexity: null,
          },
          leaveIt: null,
          protection: null,
          comment: '',
        },
        variants: action.payload.furniture.variants,
        furnitureItem: action.payload.furniture,
        id: uuid(), // only for adding furniture, allows updateById necessary for furnitureActions
        room: action.payload.room,
      }
      // Add
      if (
        state.value.filter(
          (item) =>
            item.furnitureItem.label === furnitureToUpdate.furnitureItem.label &&
            item.furnitureItem.volume === furnitureToUpdate.furnitureItem.volume &&
            item.furnitureItem.variant === furnitureToUpdate.furnitureItem.variant &&
            item.room.id === furnitureToUpdate.room.id
        ).length < action.payload.quantity
      ) {
        state.value = [...state.value, furnitureToUpdate]
      } else {
        // Remove
        const newSelectedFurniture = [...state.value]
        const furnitureToRemove = findLastIndex(
          newSelectedFurniture,
          (item: any) =>
            item.furnitureItem.label === furnitureToUpdate.furnitureItem.label &&
            item.room.id === furnitureToUpdate.room.id &&
            item.furnitureItem.variant === furnitureToUpdate.furnitureItem.variant
        )
        newSelectedFurniture.splice(furnitureToRemove, 1)
        state.value = newSelectedFurniture
      }
    },
    updateSelectedFurnitureAttentionById: (state, action) => {
      const { id, attention } = action.payload

      const selectedFurniture = [...state.value]
      const itemToUpdate = selectedFurniture.findIndex((item) => item.id === id)

      if (itemToUpdate !== -1) {
        let selectedAttentions = selectedFurniture[itemToUpdate].actions.attention
        if (selectedAttentions.includes(attention)) {
          selectedAttentions = selectedAttentions.filter(
            (selectedAttention) => selectedAttention != attention
          )
        } else {
          selectedAttentions = [...selectedAttentions, attention]
        }
        state.value[itemToUpdate] = {
          ...selectedFurniture[itemToUpdate],
          actions: { ...selectedFurniture[itemToUpdate].actions, attention: selectedAttentions },
        }
      }
    },
    updateSelectedFurnitureProtectionById: (state, action) => {
      const { id, protection } = action.payload

      const itemToUpdate = state.value.findIndex((item) => item.id === id)

      if (itemToUpdate !== -1) {
        state.value[itemToUpdate].actions.protection = protection
      }
    },
    updateSelectedFurnitureVolumeById: (state, action) => {
      const { id, volume } = action.payload

      const itemToUpdate = state.value.findIndex((item) => item.id === id)

      if (itemToUpdate !== -1) {
        state.value[itemToUpdate].furnitureItem.volume = volume
      }
    },
    updateSelectedFurnitureLeaveItById: (state, action) => {
      const { id, leaveIt } = action.payload

      const itemToUpdate = state.value.findIndex((item) => item.id === id)

      if (itemToUpdate !== -1) {
        state.value[itemToUpdate].actions.leaveIt = leaveIt
      }
    },
    updateSelectedFurnitureVariantById: (state, action) => {
      const { id, variant } = action.payload

      const itemToUpdate = state.value.findIndex((item) => item.id === id)
      if (itemToUpdate !== -1) {
        const furnitureItem = state.value[itemToUpdate].furnitureItem
        const variants = furnitureItem.variants || []
        const variantIndex = variants.findIndex((i) => i.variant == variant)
        if (variantIndex !== -1) {
          state.value[itemToUpdate].furnitureItem.variant = variants[variantIndex].variant
          state.value[itemToUpdate].furnitureItem.volume = variants[variantIndex].volume
        }
      }
    },
    updateSelectedFurnitureCommentById: (state, action) => {
      const { id, comment } = action.payload

      const itemToUpdate = state.value.findIndex((item) => item.id === id)

      if (itemToUpdate !== -1) {
        state.value[itemToUpdate].furnitureItem.comment = comment
      }
    },
    updateSelectedFurnitureMonetaryValueById: (state, action) => {
      const { id, monetary_value, currency } = action.payload

      const itemToUpdate = state.value.findIndex((item) => item.id === id)

      if (itemToUpdate !== -1) {
        state.value[itemToUpdate].furnitureItem.monetaryValue = monetary_value
        state.value[itemToUpdate].furnitureItem.currency = currency
      }
    },
    loadSheetFurnitures: (state, { payload: { rooms } }) => {
      state.value = rooms.reduce(
        (accumulator: Furniture[], current: Room) => [
          ...accumulator,
          ...current.contentFurniture.map((c) => {
            return {
              id: c.id,
              furnitureItem: {
                ...c,
                type: undefined,
              },
              actions: c.actions ?? { attention: [] },
              room: {
                ...current,
                content: undefined,
              },
            }
          }),
        ],
        []
      )
    },
    clearSelectedFurniture: (state) => {
      state.value = initialState.value
    },
    deleteFurnitureById: (state, { payload: { id } }) => {
      const selectedFurniture = [...state.value]
      const newSelectedFurniture = selectedFurniture.filter((item) => item.id !== id)
      state.value = newSelectedFurniture
    },
    updateDisassembly(state, { payload: { toDisassemble, toReassemble, complexity, id } }) {
      const selectedFurniture = [...state.value]
      const itemToUpdate = selectedFurniture.findIndex((item) => item.id === id)
      if (itemToUpdate !== -1) {
        state.value[itemToUpdate] = {
          ...selectedFurniture[itemToUpdate],
          actions: {
            ...selectedFurniture[itemToUpdate].actions,
            disassembly: {
              toDisassemble,
              toReassemble: toDisassemble && toReassemble,
              complexity,
            },
          },
        }
      }
    },
    increaseQuantity(state, { payload: { id } }) {
      const selectedFurniture = [...state.value]
      const itemToUpdate = selectedFurniture.findIndex((item) => item.id === id)
      if (itemToUpdate !== -1) {
        state.value[itemToUpdate].furnitureItem = {
          ...selectedFurniture[itemToUpdate].furnitureItem,
          quantity: (selectedFurniture[itemToUpdate].furnitureItem.quantity || 0) + 1,
        }
      }
    },
    decreaseQuantity(state, { payload: { id } }) {
      const selectedFurniture = [...state.value]
      const itemToUpdate = selectedFurniture.findIndex((item) => item.id === id)
      if (itemToUpdate !== -1) {
        state.value[itemToUpdate].furnitureItem = {
          ...selectedFurniture[itemToUpdate].furnitureItem,
          quantity: (selectedFurniture[itemToUpdate].furnitureItem.quantity || 0) - 1,
        }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateFurniture,
  updateSelectedFurniture,
  updateSelectedFurnitureAttentionById,
  updateSelectedFurnitureProtectionById,
  updateSelectedFurnitureVolumeById,
  updateSelectedFurnitureLeaveItById,
  updateSelectedFurnitureCommentById,
  updateSelectedFurnitureMonetaryValueById,
  loadSheetFurnitures,
  clearSelectedFurniture,
  deleteFurnitureById,
  addFurniture,
  removeFurniture,
  updateDisassembly,
  updateSelectedFurnitureVariantById,
  increaseQuantity,
  decreaseQuantity,
} = selectedFurnitureSlice.actions

export default selectedFurnitureSlice.reducer
