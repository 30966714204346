import { Autocomplete, FormControl, FormGroup, Popover, TextField } from '@mui/material'

import React, { FunctionComponent, ReactElement, useMemo, useRef, useState } from 'react'
import {
  handleLoadPopoverClose,
  handleSheetLoad,
} from './handlers/LoadPopoverHandlers'

import style from './LoadPopover.module.scss'
import { useDispatch } from 'react-redux'

const LoadPopover: FunctionComponent<LoadPopoverProps> = ({
  setSheetName,
  setSheetNameInput,
  setIsSaved,
}): ReactElement => {
  const anchorEl = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [availableSheets, setAvailableSheets] = useState<Array<Sheet>>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAvailableSheetsLoading, setIsAvailableSheetsLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSheetLoading, setIsSheetLoading] = useState(false)
  const dispatch = useDispatch()

  const stateSetters = useMemo(() => {
    return {
      setIsOpen,
      setAvailableSheets,
      setIsAvailableSheetsLoading,
      setIsSheetLoading,
      setSheetName,
      setSheetNameInput,
      setIsSaved,
    }
  }, [
    setIsOpen,
    setAvailableSheets,
    setIsAvailableSheetsLoading,
    setIsSheetLoading,
    setSheetName,
    setSheetNameInput,
    setIsSaved,
  ])

  return (
    <>
      {/* <LoadingButton
        color="secondary"
        variant="outlined"
        id="btn-load"
        ref={anchorEl}
        onClick={handleLoadPopoverOpen(stateSetters)}
        loading={isAvailableSheetsLoading || isSheetLoading}
      >
        <FileUpload />
        <Box component="span" sx={{ display: { xs: 'none', lg: 'block' } }}>
          &nbsp;{t('general.load')}
        </Box>
      </LoadingButton> */}
      <Popover
        className={style.popover}
        id="load-popover"
        anchorEl={anchorEl.current}
        open={isOpen}
        onClose={handleLoadPopoverClose(stateSetters)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FormControl component="fieldset">
          <FormGroup>
            <Autocomplete
              disablePortal
              id="load-popover-combo-box"
              open={isOpen}
              options={availableSheets}
              getOptionLabel={(option) => option.name ?? 'unnamed'}
              onChange={handleSheetLoad(stateSetters, dispatch)}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Estimation du volume" />}
            />
          </FormGroup>
        </FormControl>
      </Popover>
    </>
  )
}

export default LoadPopover
