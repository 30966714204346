import { Dispatch } from 'react'
import { updateSheet } from '../../../redux/sheetReducer'
import { setSheet } from '../../../services/volumeCalculator'
import volumeCalculatorSheetTransformer from '../../../services/volumeCalculatorSheetTransformer'

/**
 * @param name
 */
export const save = async (
  name = '',
  { selectedRooms, selectedBoxes, selectedFurniture, sheet }: SavePopoverStates,
  dispatch: Dispatch<any>,
  referrer: string | null
): Promise<void> => {
  const newSheet = await setSheet(
    sheet.id,
    name,
    volumeCalculatorSheetTransformer(sheet.name, referrer, selectedRooms, selectedBoxes, selectedFurniture)
  )
  dispatch(updateSheet(newSheet))
}
