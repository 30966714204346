export const serviceClient = async (req, extra = {}) => {
  const { url, method } = req
  const { filters = null, body = null, id = null } = extra
  const options = {
    method,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }
  if (body) {
    options['body'] = JSON.stringify(body)
  }

  const urlFormatted = new URL(url.replace(':id', id))
  filters &&
    Object.entries(filters).forEach(([key, value]) => urlFormatted.searchParams.append(key, value))

  const res = await fetch(urlFormatted, options)

  return res.json()
}
