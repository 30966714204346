import React, { FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from '@mui/material'
import BuildIcon from '@mui/icons-material/Build'
import { useDispatch } from 'react-redux'
import { updateDisassembly } from '../../../../redux/selectedFurnitureReducer'

const Disassembly: FunctionComponent<FurnitureActionsProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { furniture, md = 3 } = props
  const { id } = furniture

  const dispatch = useDispatch()

  const {
    toDisassemble = false,
    toReassemble = false,
    complexity = null,
  } = furniture.actions.disassembly

  return (
    <>
      <Grid item xs={12} md={md}>
        <Box sx={{ mb: 2, p:2 }}>
          <Box>
            <BuildIcon sx={{ paddingTop: '5px' }}/>{' '}
            <span>
            {t('general.disassembly_reassembly')}
            </span>
          </Box>
          <Box>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={toDisassemble}
                      onChange={() => {
                        dispatch(
                          updateDisassembly({
                            toDisassemble: !toDisassemble,
                            complexity,
                            toReassemble: !toReassemble,
                            id,
                          }), 
                        )
                      }}
                      name="to_disassemble"
                      color="primary"
                    />
                  }
                  label={t('furniture_actions.to_disassemble')}
                />
              </FormGroup>
              {toDisassemble && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={toReassemble}
                        onChange={() => {
                          dispatch(
                            updateDisassembly({
                              toDisassemble,
                              complexity,
                              toReassemble: !toReassemble,
                              id,
                            })
                          )
                        }}
                        name="to_reassemble"
                        color="primary"
                      />
                    }
                    label={t('furniture_actions.to_reassemble')}
                  />
                </FormGroup>
              )}
            </FormControl>
            {toDisassemble && (
              <FormGroup>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="disassembly-complexity-label">
                    {t('general.complexity')}
                  </InputLabel>
                  <Select
                    labelId="disassembly-complexity-label"
                    id="disassembly-complexity-select"
                    value={complexity || ''}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        updateDisassembly({
                          toDisassemble,
                          complexity: value,
                          toReassemble,
                          id,
                        })
                      )
                    }}
                    label="Age"
                  >
                    <MenuItem value="simple">{t('furniture_actions.disassembly_simple')}</MenuItem>
                    <MenuItem value="medium">{t('furniture_actions.disassembly_medium')}</MenuItem>
                    <MenuItem value="hard">{t('furniture_actions.disassembly_hard')}</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            )}
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default Disassembly
