import { Edit } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { FunctionComponent, ReactElement } from 'react'
import style from '../../SelectedFurnitureItem.module.scss'
import { useTranslation } from 'react-i18next'

const EditItIcon: FunctionComponent<EditIconProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { onClick } = props
  return (
    <IconButton className={style.iconButton} title={t('general.edit_message')} onClick={onClick}>
      <Edit />
    </IconButton>
  )
}

export default EditItIcon
