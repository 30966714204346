import React, { FunctionComponent, ReactElement, useRef } from 'react'
import Header from '../Header/Header'
import Navigation from '../Navigation/Navigation'
import Room from '../Room/Room'
import Sidebar from '../Sidebar/Sidebar'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setLocale } from '../../i18n'
import i18next from 'i18next'

const Main: FunctionComponent = (): ReactElement => {
  const selectedRooms = useSelector((state: RootState) => state.selectedRooms.value)

  // Prevents to initialize i18n multiple times, doesn't work with useEffect
  if (!i18next.isInitialized) {
    const { locale } = useParams()
    setLocale(locale)
  }

  const itemsRef = useRef<HTMLDivElement[]>([])
  const navigationContainerRef = useRef<HTMLDivElement>(null)

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Header itemsRef={itemsRef} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          {selectedRooms.length > 0 && (
            <Navigation
              rooms={selectedRooms}
              containerRef={navigationContainerRef}
              itemsRef={itemsRef}
            />
          )}
          {selectedRooms.map((selectedRoom: SelectedRoom, index: number) => (
            <Room
              key={index}
              itemsRef={itemsRef}
              room={selectedRoom}
              navigationContainerRef={navigationContainerRef}
            />
          ))}
        </div>
        <div className="col-lg-4">
          <Sidebar itemsRef={itemsRef} />
        </div>
      </div>
    </div>
  )
}

export default Main
