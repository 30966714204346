import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, FormControlLabel, FormGroup, Grid, Input } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { updateSelectedFurnitureVolumeById } from '../../../../redux/selectedFurnitureReducer'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/system'

const Edit: FunctionComponent<FurnitureActionsProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { furniture, md = 3 } = props

  const dispatch = useDispatch()

  const handleVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSelectedFurnitureVolumeById({
        id: furniture.id,
        volume: event.target.value.replace(',', '.'),
      })
    )
  }

  return (
    <>
      <Grid item xs={12} md={md}>
        <Box sx={{ mb: 2 }}>
          <Box>
            <EditIcon sx={{ paddingTop: '5px' }}/>{' '}
            {t('general.volume')}
          </Box>
          <Box>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Input
                      sx={{ width: '80%', marginLeft: '10px' }}
                      value={furniture.furnitureItem.volume}
                      onChange={handleVolumeChange}
                      name={t('general.volume')}
                      color="primary"
                      fullWidth={true}
                    />
                  }
                  label="m3"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default Edit
