import { Dispatch } from '@reduxjs/toolkit'
import { MutableRefObject } from 'react'
import { clearSelectedBoxes } from '../../../redux/selectedBoxesReducer'
import { clearSelectedFurniture } from '../../../redux/selectedFurnitureReducer'
import { clearSheetRooms } from '../../../redux/selectedRoomsReducer'
import { clearSheet } from '../../../redux/sheetReducer'

export const handleNewSheet =
  (
    dispatch: Dispatch<any>,
    setSheetName: React.Dispatch<React.SetStateAction<string | undefined>>,
    itemsRef: MutableRefObject<HTMLDivElement[]>
  ) =>
  (): void => {
    dispatch(clearSheet())
    dispatch(clearSheetRooms())
    dispatch(clearSelectedFurniture())
    dispatch(clearSelectedBoxes())
    setSheetName('')
    itemsRef.current = new Array<HTMLDivElement>()
  }
