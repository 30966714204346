export const countFurniturePerRoom =
  (room: SelectedRoom) =>
  (furnitureList: Array<SelectedFurniture>, currentFurniture: FurnitureItem): number =>
    furnitureList.filter(
      (i) => i.furnitureItem.key === currentFurniture.key && i.room.id === room.id
    ).length
export const countBoxPerRoom =
  (room: SelectedRoom) =>
  (furnitureList: Array<SelectedBox>, currentBox: BoxItem): number =>
    furnitureList
      .filter((i) => i.label === currentBox.label && i.room.id === room.id)
      .reduce((accumulator, current) => accumulator + current.quantity, 0)
