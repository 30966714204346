import { Policy } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { FunctionComponent, ReactElement } from 'react'
import style from '../../SelectedFurnitureItem.module.scss'
import { useTranslation } from 'react-i18next'

const ProtectionIcon: FunctionComponent<ProtectionIconProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { isProtectionNeeded, onClick } = props
  return (
    <IconButton
      className={style.iconButton}
      color={isProtectionNeeded ? 'primary' : undefined}
      title={t('general.protection')}
      onClick={onClick}
    >
      <Policy />
    </IconButton>
  )
}

export default ProtectionIcon
