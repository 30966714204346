import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Box } from '@mui/material'
import { Warning } from '@mui/icons-material'
import { updateSelectedFurnitureAttentionById } from '../../../../redux/selectedFurnitureReducer'
import { useDispatch } from 'react-redux'

const Attention: FunctionComponent<FurnitureActionsProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { furniture, md = 3 } = props

  const dispatch = useDispatch()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSelectedFurnitureAttentionById({ id: furniture.id, attention: event.target.name })
    )
  }

  return (
    <>
      <Grid item xs={12} md={md} sx={{ pl: 1, pb:1 }}>
        <Box sx={{ mb: 2, p:2 }}>
          <Box sx={{ mb: 2 }}>
            <Warning sx={{ pt: '5px' }}/>{' '}
            {t('general.attention')}
          </Box>
          <Box>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={furniture.actions.attention.includes('top_of_the_range')}
                      onChange={handleChange}
                      name="top_of_the_range"
                      color="primary"
                    />
                  }
                  label={t('furniture_actions.top_of_the_range')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={furniture.actions.attention.includes('antique')}
                      onChange={handleChange}
                      name="antique"
                      color="primary"
                    />
                  }
                  label={t('furniture_actions.antique')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={furniture.actions.attention.includes('old')}
                      onChange={handleChange}
                      name="old"
                      color="primary"
                    />
                  }
                  label={t('furniture_actions.old')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={furniture.actions.attention.includes('fragile')}
                      onChange={handleChange}
                      name="fragile"
                      color="primary"
                    />
                  }
                  label={t('furniture_actions.fragile')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={furniture.actions.attention.includes('unstable')}
                      onChange={handleChange}
                      name="unstable"
                      color="primary"
                    />
                  }
                  label={t('furniture_actions.unstable')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={furniture.actions.attention.includes('heavy')}
                      onChange={handleChange}
                      name="heavy"
                      color="primary"
                    />
                  }
                  label={t('furniture_actions.heavy')}
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default Attention
