import { Dispatch } from '@reduxjs/toolkit'
import React, { ChangeEvent } from 'react'

import { validateSheetName } from '../../../services/volumeCalculatorValidator'
import { sheetNamePrefix } from '../helpers/HeaderHelpers'
import { save } from '../helpers/SavePopoverHelpers'
import { enqueueSnackbar } from 'notistack'

interface StateSetters {
  setSheetName: React.Dispatch<React.SetStateAction<string | undefined>>
  setSheetNameInput: React.Dispatch<React.SetStateAction<string>>
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<string>>
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * Handle input 'name' changes; validate value and display error message
 * @param event
 */
export const handleChange =
  ({ setSheetName }: StateSetters) =>
  async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const name = event.target.value
    setSheetName(`${sheetNamePrefix}_${name}`)
  }

/**
 * Handle popover save
 * Do nothing if error is occurred
 * Save sheet if no error occurred
 */
export const handleSavePopover =
  (
    sheetName: string | undefined,
    states: SavePopoverStates,
    { setIsOpen, setError, setIsSaved }: StateSetters,
    dispatch: Dispatch<any>,
    ERROR: ErrorType,
    referrer: string | null
  ) =>
  async (): Promise<void> => {
    const { error } = states

    if (sheetName && !(await validateSheetName(sheetName))) {
      setError(ERROR.ALREADY_USED)
    } else {
      if (sheetName && !error) {
        await save(sheetName, states, dispatch, referrer)
        setIsSaved(true)
      }
      setIsOpen(false)
    }
  }

/**
 * Handle popover opening
 * @param forced
 * @returns () => void
 */
export const handleSavePopoverOpen =
  (
    sheetName: string | undefined,
    states: SavePopoverStates,
    { setIsOpen }: StateSetters,
    dispatch: Dispatch<any>,
    forced = false,
    referrer: string | null,
    successMessage: string,
    errorMessage: string,
  ) =>
  (): void => {
    const { error } = states
    if (error || forced) {
      setIsOpen(true)
      enqueueSnackbar(errorMessage, { variant: 'success' })
    } else {
      save(sheetName, states, dispatch, referrer)
      enqueueSnackbar(successMessage, { variant: 'success' })
    }
  }
