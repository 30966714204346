import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { Block } from '@mui/icons-material'
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateSelectedFurnitureLeaveItById } from '../../../../redux/selectedFurnitureReducer'

const leaveItList = ['is_left', 'closet', 'dump']

const LeaveIt: FunctionComponent<FurnitureActionsProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { furniture, md = 3 } = props

  const dispatch = useDispatch()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateSelectedFurnitureLeaveItById({ id: furniture.id, leaveIt: event.target.value }))
  }

  return (
    <>
      <Grid item xs={12} md={md} sx={{ pl: 1, pb:1 }}>
        <Box sx={{ mb: 2 }}>
          <Block sx={{ pt: '5px' }}/>{' '}
          {t('general.leave_it')}
        </Box>
        <Box>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="protection"
              defaultValue=""
              name="protection"
              onChange={handleChange}
            >
              <FormControlLabel
                key="empty"
                value=""
                control={<Radio />}
                label={t(`furniture_actions.is_left_none`)}
                checked={!furniture.actions.leaveIt}
              />
              {leaveItList.map((leaveItValue) => (
                <FormControlLabel
                  key={leaveItValue}
                  value={leaveItValue}
                  control={<Radio />}
                  label={t(`furniture_actions.is_left_${leaveItValue}`)}
                  checked={furniture.actions.leaveIt == leaveItValue}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
    </>
  )
}

export default LeaveIt
