import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import style from './Box.module.scss'
import { Card, CardActions, CardContent, IconButton } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { countBoxPerRoom } from '../../utils/countContentPerRoom'
import { createSelector } from 'reselect'
import { addBox, removeBox } from '../../redux/selectedBoxesReducer'
import Icon from '../Icon/Icon'

const Box: FunctionComponent<BoxProps> = (props) => {
  const { t } = useTranslation()
  const { box, room } = props

  const dispatch = useDispatch()

  const handleIncrease = (): void => {
    dispatch(addBox({ box, room }))
  }

  const handleDecrease = (): void => {
    dispatch(removeBox({ box, room }))
  }

  const countQuantitiesForBox = (counter: BoxCounterCallback) =>
    createSelector(
      // first transformer function for the first argument
      (state: CounterState<SelectedBox>) => state.selectedBoxes.value,
      // second transformer function for the second argument
      (_: unknown, box: BoxItem) => box,
      // combinator function who take all previous function's result as arguments, in the same order
      // will be equivalent to :
      /*
  counter(
    ((state:CounterState<SelectedFurniture>) =>  state.selectedFurniture.value)(state), 
    ((_:unknown, furniture:FurnitureItem) => furniture)(null, funiture),
  )
  */
      counter
    )

  // real quantity value, fetched from furniture list.
  // call the selector previously created
  const quantity = useSelector((state: CounterState<SelectedBox>) =>
    countQuantitiesForBox(countBoxPerRoom(room))(state, box)
  )

  return (
    <div className="col-xs-6 col-sm-4 col-md-2">
      <Card className={`${style.container} ${quantity > 0 ? style.hasQuantity : ''}`}>
        <CardContent className={style.content}>
          <h3 className={style.title}>{t('boxes.box')}</h3>
          <p className={style.variant}>{t(`boxes.${box.label}`)}</p>
          <div className={style.image}>
            <Icon name={box.label.replaceAll(' ', '_')} path="boxes" />
          </div>
        </CardContent>
        <CardActions className={style.actions}>
          <IconButton onClick={handleDecrease} disabled={quantity < 1}>
            <Remove />
          </IconButton>
          <div className={style.quantity}>{quantity}</div>
          <IconButton onClick={handleIncrease}>
            <Add />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  )
}

export default Box
