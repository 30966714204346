import { Button } from '@mui/material'
import React, { ReactElement } from 'react'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import 'reactjs-popup/dist/index.css'

interface PDFLinkProps {
  color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  sheetId: string
  type: string
  children: ReactNode
}

const PDFLink = ({ color, sheetId, children, type }: PDFLinkProps): ReactElement => {
  const { locale = 'fr' } = useParams()

  const url = `${process.env.REACT_APP_BACK_SHEET}/sheets/${locale}/${sheetId}.pdf?model=${type}`

  return (
    <Button color={color} variant="contained" href={url} target="_blank">
      {children}
    </Button>
  )
}

export default PDFLink
