import { configureStore } from '@reduxjs/toolkit'
import selectedFurnitureReducer from './selectedFurnitureReducer'
import selectedRoomsReducer from './selectedRoomsReducer'
import selectedBoxesReducer from './selectedBoxesReducer'
import sheetReducer from './sheetReducer'

const store = configureStore({
  reducer: {
    selectedBoxes: selectedBoxesReducer,
    selectedFurniture: selectedFurnitureReducer,
    selectedRooms: selectedRoomsReducer,
    sheet: sheetReducer,
  },
})
export default store
