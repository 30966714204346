import { createSlice } from '@reduxjs/toolkit'
import { findLastIndex } from 'lodash'
import { v4 as uuid } from 'uuid'

const initialState: SelectedBoxesState = {
  value: [],
}

export const selectedBoxesSlice = createSlice({
  name: 'selectedBoxes',
  initialState,
  reducers: {
    addBox: (state, action) => {
      const boxesToUpdate: SelectedBox = {
        packing: action.payload.box.packing,
        label: action.payload.box.label,
        quantity: 1,
        id: uuid(),
        room: action.payload.room,
        volume: action.payload.box.volume,
        variant: action.payload.box.variant,
      }
      const newSelectedBoxes = [...state.value]
      const boxIndex = findLastIndex(
        newSelectedBoxes,
        (item) => item.label === action.payload.box.label && item.room.id === action.payload.room.id
      )
      if (boxIndex >= 0) {
        newSelectedBoxes[boxIndex].quantity++
        state.value = newSelectedBoxes
      } else {
        state.value = [...state.value, boxesToUpdate]
      }
    },
    removeBox: (state, action) => {
      const newSelectedBoxes = [...state.value]
      const boxIndex = findLastIndex(
        newSelectedBoxes,
        (item: any) =>
          item.label === action.payload.box.label && item.room.id === action.payload.room.id
      )
      if (boxIndex >= 0) {
        if (newSelectedBoxes[boxIndex].quantity && newSelectedBoxes[boxIndex].quantity > 1) {
          newSelectedBoxes[boxIndex].quantity--
        } else {
          newSelectedBoxes.splice(boxIndex, 1)
        }
      }
      state.value = newSelectedBoxes
    },
    updateSelectedBoxPacking: (state, action) => {
      const { label, packing } = action.payload
      state.value = state.value.map((item) =>
    item.label === label ? { ...item, packing } : item
  )
    },
    loadSheetBoxes: (state, { payload: { rooms } }) => {
      state.value = []
      rooms.forEach((current: { contentBox: any[] }) => {
        state.value.push(
          ...current.contentBox.map((c) => {
            return {
            ...c,
            room: { ...current, content: undefined },
            type: undefined,
          }})
        )
      })
    },
    clearSelectedBoxes: (state) => {
      state.value = initialState.value
    },
  },
})

// Action creators are generated for each case reducer function
export const { loadSheetBoxes, addBox, removeBox, updateSelectedBoxPacking, clearSelectedBoxes } = selectedBoxesSlice.actions

export default selectedBoxesSlice.reducer
