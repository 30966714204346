import React, { ReactNode, useRef } from "react"
import { MaterialDesignContent, SnackbarKey, SnackbarProvider } from "notistack"
import { Button, GlobalStyles } from "@mui/material"
import { styled, useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'

function SnackbarStyles() {
  const theme = useTheme()

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .notistack-Snackbar': {
            marginTop: theme.spacing(9),
            backgroundColor: `${theme.palette.grey[100]} !important`,
          },
          '&.notistack-MuiContent, &.notistack-MuiContent-success, &.notistack-MuiContent-error, &.notistack-MuiContent-warning, &.notistack-MuiContent-info': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
          
            
          },
          '& #notistack-snackbar': {
              width: '100%',
              borderRadius: theme.shape.borderRadius,
              backgroundColor: theme.palette.grey[100],
              
              [theme.breakpoints.up('md')]: {
                minWidth: 240,
              },
              '& .MuiSvgIcon-root': {
                marginRight: 10
              },
            },
            '& #closeIcon': {
              color: theme.palette.text.primary,
              width: 10, 
              height: 10,
              marginTop: theme.spacing(-5),
              marginLeft: 'auto',
              backgroundColor: theme.palette.grey[100],
            },
        },
      }}
    />
  )
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => {
  return {
  '&.notistack-MuiContent-success, &.notistack-MuiContent-error, &.notistack-MuiContent-warning, &.notistack-MuiContent-info': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[100],
  },
}})

type Props = {
  children: ReactNode
}

export default function CustomSnackbarProvider({ children }: Props) {
  const snackbarRef = useRef<any>(null)

  const onClose = (key: SnackbarKey) => () => {
    snackbarRef.current.closeSnackbar(key)
  }
  

  return (
    <>
      <SnackbarStyles />
      <SnackbarProvider
        ref={snackbarRef}
        dense
        preventDuplicate
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <InfoIcon color="info"/>,
          success: <CheckCircleIcon color="success" />,
          warning: <WarningIcon color="warning" />,
          error: <ErrorIcon color="error" />,
        }}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
        }}
        action={(key) => (
          <Button size="small" id="closeIcon" onClick={onClose(key)} >
            <CloseIcon/>
          </Button>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  )
}
