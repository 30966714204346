import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import style from './Furniture.module.scss'
import { Card, CardContent, CardActions, IconButton } from '@mui/material'
import Icon from '../Icon/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { countFurniturePerRoom } from '../../utils/countContentPerRoom'
import { addFurniture } from '../../redux/selectedFurnitureReducer'
import { Add } from '@mui/icons-material'

// redux selector to link redux store and component state.
// without this, the component will not refresh when sidebar component change the value
const countQuantitiesForFurniture = (counter: FurnitureCounterCallback) =>
  createSelector(
    // first transformer function for the first argument
    (state: CounterState<SelectedFurniture>) => state.selectedFurniture.value,
    // second transformer function for the second argument
    (_: unknown, furniture: FurnitureItem) => furniture,
    // combinator function who take all previous function's result as arguments, in the same order
    // will be equivalent to :
    /*
  counter(
    ((state:CounterState<SelectedFurniture>) =>  state.selectedFurniture.value)(state), 
    ((_:unknown, furniture:FurnitureItem) => furniture)(null, funiture),
  )
  */
    counter
  )

const Furniture: FunctionComponent<FurnitureProps> = (props) => {
  const { t } = useTranslation()
  const { furniture, room } = props
  const dispatch = useDispatch()

  const handleIncrease = (): void => {
    dispatch(addFurniture({ furniture: { quantity: 1, ...furniture }, room }))
  }

  // real quantity value, fetched from furniture list.
  // call the selector previously created
  const quantity = useSelector((state: CounterState<SelectedFurniture>) =>
    countQuantitiesForFurniture(countFurniturePerRoom(room))(state, furniture)
  )

  return (
    <div className="col-xs-6 col-sm-4 col-md-2">
      <Card className={`${style.container} ${quantity > 0 ? style.hasQuantity : ''}`}>
        <CardContent className={style.content}>
          <h3 className={style.title}>{t(`furniture.${furniture.label}`)}</h3>
          <div className={style.image}>
            <Icon name={furniture.label.replaceAll(' ', '_')} path="furniture" />
          </div>
        </CardContent>
        <CardActions className={style.actions}>
          <IconButton onClick={handleIncrease}>
            <Add />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  )
}

export default Furniture
