import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { updateSelectedFurnitureVariantById } from '../../../../redux/selectedFurnitureReducer'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/system'

const Edit: FunctionComponent<FurnitureActionsProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { furniture, md = 3 } = props

  const dispatch = useDispatch()

  const handleVariantChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateSelectedFurnitureVariantById({ id: furniture.id, variant: event.target.value }))
  }

  return (
    <>
      <Grid item xs={12} md={md}>
        <Box sx={{ mb: 2, p:2 }}>
          <Box>
            <EditIcon sx={{ paddingTop: '5px' }}/>{' '}
            {t('general.variant')}
          </Box>
          <Box>
            {(furniture.furnitureItem.variants && (
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="variant"
                  defaultValue=""
                  name="variant"
                  onChange={handleVariantChange}
                >
                  {furniture.furnitureItem.variants?.slice().sort((a: any, b: any) => {
                    if (a.variant && b.variant && a.label === b.label) {
                      return a.volume - b.volume
                    } else {
                      return 0
                    }
                  })
                  .map((variant) => (
                    <FormControlLabel
                      key={variant.variant}
                      value={variant.variant}
                      control={<Radio />}
                      label={t(`furniture.variants.${variant.variant}`)}
                      checked={furniture.furnitureItem.variant == variant.variant}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )) ||
              t(`general.no_variant`)}
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default Edit
