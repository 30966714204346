import { SHEETS_CREATE, SHEETS_GET_ALL, SHEETS_UPDATE, serviceClient } from '../api'

export const setSheet = async (id: string, name: string, sheet: any): Promise<Sheet | void> => {
  try {
    if (!name) {
      throw new Error('Le nom est obligatoire')
    }
    sheet.name = name
    let request = SHEETS_CREATE
    if (id) {
      request = SHEETS_UPDATE
    }
    const items = await serviceClient(request, { id, body: sheet })
    return { id: items.id, name }
  } catch (error: unknown) {
    console.error(error)
    return undefined
  }
}

export const getSheets = async (): Promise<Array<Sheet> | undefined> => {
  try {
    return await serviceClient(SHEETS_GET_ALL)
  } catch (error: unknown) {
    console.error(error)
    return undefined
  }
}

export const getSheetByName = async (sheetName: string | undefined): Promise<Sheet | undefined> => {
  try {
    return (await getSheets())?.find((sheet: Sheet) => sheet.name === sheetName) || undefined
  } catch (error: unknown) {
    console.error(error)
    return undefined
  }
}
