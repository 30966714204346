import React, { FunctionComponent, ReactElement } from 'react'
import style from '../../SelectedFurnitureItem.module.scss'
import { DeleteForever } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { deleteFurnitureById } from '../../../../redux/selectedFurnitureReducer'

const Delete: FunctionComponent<DeleteActionProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { id } = props
  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(deleteFurnitureById({ id }))
  }

  return (
    <>
      <IconButton
        className={`${style.iconButton}`}
        title={t('general.delete_it')}
        onClick={handleDelete}
      >
        <DeleteForever />
      </IconButton>
    </>
  )
}

export default Delete
