import { Block } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { FunctionComponent, ReactElement } from 'react'
import style from '../../SelectedFurnitureItem.module.scss'
import { useTranslation } from 'react-i18next'

const LeaveItIcon: FunctionComponent<LeaveItIconProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { isLeftBehind, onClick } = props
  return (
    <IconButton
      className={style.iconButton}
      color={isLeftBehind ? 'primary' : undefined}
      title={t('general.leave_it')}
      onClick={onClick}
    >
      <Block />
    </IconButton>
  )
}

export default LeaveItIcon
