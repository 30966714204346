import { getSheetByName } from './volumeCalculator'

export const validateSheetName = async (name: string): Promise<boolean | void> => {
  try {
    const existingSheet = await getSheetByName(name)

    return existingSheet === undefined
  } catch (error: unknown) {
    console.error(error)
    return false
  }
}
