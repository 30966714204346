import { createSlice } from '@reduxjs/toolkit'

type sheetState = {
  value: Sheet
}

const initialState: sheetState = {
  value: { id: '', name: '' },
}

export const sheetSlice = createSlice({
  name: 'sheet',
  initialState,
  reducers: {
    updateSheet: (state, { payload: { id = '', name = '' } }) => {
      state.value.id = id
      state.value.name = name
    },
    clearSheet: (state) => {
      state.value = initialState.value
    },
    loadSheet: (state, { payload: { id, name } }) => {
      state.value = { id, name }
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateSheet, clearSheet, loadSheet } = sheetSlice.actions

export default sheetSlice.reducer
