import React, { FunctionComponent, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Add, Remove } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import {
  decreaseQuantity,
  deleteFurnitureById,
  increaseQuantity,
} from '../../../../redux/selectedFurnitureReducer'

const QuantityAction: FunctionComponent<QuantityActionProps> = (props): ReactElement => {
  const { id } = props

  const dispatch = useDispatch()
  const selectedFurniture = useSelector((state: RootState) => {
    const furnitureIndex = state.selectedFurniture.value.findIndex((item: any) => item.id === id)
    if (furnitureIndex !== -1) {
      return state.selectedFurniture.value[furnitureIndex]
    }
    return null
  })

  const handleIncrease = () => {
    dispatch(increaseQuantity({ id }))
  }

  const handleDecrease = () => {
    if (selectedFurniture.furnitureItem.quantity === 1) {
      dispatch(deleteFurnitureById({ id }))
    } else {
      dispatch(decreaseQuantity({ id }))
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton onClick={handleDecrease}>
          <Remove />
        </IconButton>
        <div style={{ alignSelf: 'center' }}>{selectedFurniture.furnitureItem.quantity}</div>
        <IconButton onClick={handleIncrease}>
          <Add />
        </IconButton>
      </div>
    </>
  )
}

export default QuantityAction
