import React, { ChangeEvent, FunctionComponent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Card,
  CardContent,
  CardActions,
  IconButton,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material'
import style from '../Furniture/Furniture.module.scss'
import { Add } from '@mui/icons-material'
import { addFurniture } from '../../redux/selectedFurnitureReducer'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { translateItems } from '../../utils/translateItems'
import { addBox } from '../../redux/selectedBoxesReducer'

const CustomFurniture: FunctionComponent<CustomFurnitureProps> = (props): ReactElement => {
  const { furniture, room, boxes } = props
  const { t } = useTranslation()

  // display furnitures with i18n
  const translatedFurnituresList = translateItems(furniture, 'furniture')
  const translatedBoxesList = translateItems(boxes, 'boxes')

  const [filteredItemsList, setFilteredItemsList] = useState(
    translatedBoxesList.concat(translatedFurnituresList)
  )
  const [quantity, setQuantity] = useState(0)

  const [item, setItem] = useState<Item>({
    volume: 0,
    label: '',
    key: '',
    rooms: '',
    comment: '',
    quantity: 1,
  })

  const dispatch = useDispatch()

  const handleIncrease = (): void => {
    if (item.type == 'boxes') {
      dispatch(addBox({ box: { ...item, label: item.key }, room }))
    } else {
      dispatch(addFurniture({ furniture: { quantity: 1, ...item }, room }))
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (quantity > 0) {
      setQuantity(0)
    }
    if (event.currentTarget.value !== '' && parseFloat(event.currentTarget.value) >= 0) {
      setItem({
        ...item,
        volume: event.currentTarget.value ? parseFloat(event.currentTarget.value) : 0,
      })
    }
  }

  useEffect(() => {
    if (item?.label)
      setFilteredItemsList(
        translatedFurnituresList
          .concat(translatedBoxesList)
          .filter((translatedItem) =>
            translatedItem.label.toLowerCase().startsWith(item.label.toLowerCase())
          )
      )
    else setFilteredItemsList(translatedFurnituresList.concat(translatedBoxesList))
  }, [item?.label])

  const handleInputChange = (newValue: string) => {
    if (quantity > 0) {
      setQuantity(0)
    }
    setItem({
      ...item,
      key: newValue,
      label: newValue,
    })
  }

  return (
    <div className="col-sm-8 col-md-4">
      <Card className={`${style.container} ${quantity > 0 ? style.hasQuantity : ''}`}>
        <CardContent className={style.content}>
          <div className={style.formInput}>
            <Autocomplete
              freeSolo
              id="furnitureName"
              value={item}
              options={filteredItemsList}
              onChange={(_event: any, newValue: any) => {
                setItem(newValue as Item)
              }}
              onInputChange={(event: any, newValue: string) => handleInputChange(newValue)}
              fullWidth={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={t('general.add_furniture')}
                  variant="outlined"
                  name="furnitureName"
                  value={item?.label}
                  fullWidth={true}
                />
              )}
            />
          </div>
          <div className={style.volume}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="furnitureVolume">{t('general.volume')}</InputLabel>
              <OutlinedInput
                size="small"
                label={t('general.volume')}
                id="furnitureVolume"
                name="furnitureVolume"
                value={item?.volume}
                type="number"
                endAdornment={
                  <InputAdornment position="end">
                    m<sup>3</sup>
                  </InputAdornment>
                }
                onChange={handleChange}
              />
            </FormControl>
          </div>
        </CardContent>

        <CardActions className={style.actions}>
          <IconButton
            onClick={handleIncrease}
            disabled={item && (item.label === '' || item.volume === 0)}
          >
            <Add />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  )
}

export default CustomFurniture
