import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import style from './DefaultRoom.module.scss'
import usePrevious from '../../utils/hooks/usePrevious'
import { useSelector } from 'react-redux'
import { DefaultRoomProps } from '../../definitions/props/defaultRoom'

const DefaultRoom: FunctionComponent<DefaultRoomProps> = (props): ReactElement => {
  const { onQuantityChange, room } = props
  const { t } = useTranslation()

  const [quantity, setQuantity] = useState(0)

  const previousValues = usePrevious(quantity)

  const selectedRooms = useSelector((state: RootState) => state.selectedRooms.value)

  const updateQuantity = useCallback(
    (quantity: number) => {
      onQuantityChange(quantity)
    },
    [onQuantityChange]
  )

  useEffect(() => {
    if (previousValues !== undefined && quantity !== previousValues) {
      updateQuantity(quantity)
    }
  }, [previousValues, quantity, updateQuantity])

  useEffect(() => {
    const selectedRoomOccurences = selectedRooms.filter((r: SelectedRoom) => r.key === room.key)
    if (quantity != selectedRoomOccurences.length) {
      setQuantity(selectedRoomOccurences.length)
    }
  }, [selectedRooms, room])

  const handleIncrease = () => {
    setQuantity(quantity + 1)
  }

  const handleDecrease = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1)
    }
  }

  return (
    <div className={style.container}>
      <h3 className={style.label}>{t(`rooms.${room.label}`, { defaultValue: room.label })}</h3>
      <div className={style.actions}>
        <IconButton onClick={handleDecrease} disabled={quantity < 1}>
          <Remove />
        </IconButton>
        <div className={style.quantity}>{quantity}</div>
        <IconButton onClick={handleIncrease}>
          <Add />
        </IconButton>
      </div>
    </div>
  )
}

export default DefaultRoom
