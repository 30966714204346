import { Card, CardContent } from '@mui/material'
import React, {
  useState,
  useEffect,
  FunctionComponent,
  MutableRefObject,
  ReactElement,
  useCallback,
  useMemo,
} from 'react'
import { DocumentContent } from 'kuzzle-sdk'
import DefaultRoom from './DefaultRoom'
import { useTranslation } from 'react-i18next'
import style from './Sidebar.module.scss'
import SelectedFurnitureItem from '../SelectedFurnitureItem/SelectedFurnitureItem'
import SelectedBoxItem from '../SelectedBoxItem/SelectedBoxItem'
import SelectedBox from '../SelectedBoxItem/SelectedBox'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedRooms } from '../../redux/selectedRoomsReducer'
import ActionModal from '../SelectedFurnitureItem/Actions/ActionsModal'
import { useQuery } from 'react-query'
import { ROOMS_GET_ALL, serviceClient } from '../../api'

const getFurnitureModal = (
  furniture: SelectedFurniture,
  open: boolean,
  onClose: () => void
): ReactElement => <ActionModal onClose={onClose} furniture={furniture} open={open} />

const Sidebar: FunctionComponent<SidebarProps> = (props): ReactElement => {
  const { itemsRef } = props
  const { t } = useTranslation()
  
  const selectedRooms = useSelector((state: RootState) => state.selectedRooms.value)
  const selectedFurniture = useSelector((state: RootState) => state.selectedFurniture.value)
  const selectedBoxes = (useSelector((state: RootState) => state.selectedBoxes.value))
  const dispatch = useDispatch()
  const [totalVolumeFurniture, setTotalVolumeFurniture] = useState<number>(0)
  const [totalVolumeBox, setTotalVolumeBox] = useState<number>(0)
  const {
    isLoading,
    isError,
    data,
    error,
  }: { isLoading: boolean; isError: boolean; data: any; error: any } = useQuery(['DEFAULT_ROOMS'], {
    queryFn: () => serviceClient(ROOMS_GET_ALL, {}),
  })

  let sortedRooms = []
  if (data) {sortedRooms = data.slice().sort((a: any, b: any) => a.order - b.order)}

  const onQuantityChange =
    (defaultRoom: SelectedRoom, _itemsRef: MutableRefObject<HTMLDivElement[]>) =>
    (quantity: number) => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
      handleQuantityChange(quantity, defaultRoom)
      _itemsRef.current = new Array<HTMLDivElement>()
    }
  const handleQuantityChange = useCallback((quantity: number, room: DocumentContent) => {
    dispatch(updateSelectedRooms({ quantity, room }))
  }, [])

  const roomsWithBoxAndFurniture: RoomsWithBoxAndFurniture = useMemo(
    () =>
      selectedRooms.filter((room: SelectedRoom) =>
        selectedBoxes.some((box: SelectedBox) => box.room.id === room.id) ||
        selectedFurniture.some((furniture: SelectedFurniture) => furniture.room.id === room.id)       
      ),      
    [selectedRooms, selectedBoxes, selectedFurniture]
  )

  // https://stackoverflow.com/questions/39985152/sum-all-the-values-in-an-object-by-filtering-country-attribute-javascript
  const boxes = useMemo(
    () =>
      Array.from(
        selectedBoxes.reduce(
          (
            boxes: Map<
              string,
              { quantity: number; room: SelectedRoom; variant: string; volume: number; packing: string }
            >,
            item: SelectedBox
          ) =>
            boxes.set(item.label, {
              quantity: (boxes.get(item.label)?.quantity || 0) + item.quantity,
              room: item.room,
              variant: item.variant,
              volume: item.volume,
              packing: item.packing
            }),
          new Map<
            string,
            { quantity: number; room: SelectedRoom; variant: string; volume: number; packing: string }
          >()
        ),
        ([label, { quantity, room, variant,packing, volume }]) => {
          return { label, quantity, room, variant, packing, volume }
        }
      ),
    [selectedBoxes]
  )
  
  useEffect(() => {
    const filteredFurniture = selectedFurniture.filter((currentValue: any) => currentValue.actions?.leaveIt !== 'is_left')
    const totalVolumeFurniture = filteredFurniture
      .reduce((acc: any, currentValue: any) => {
        const { quantity, volume } = currentValue.furnitureItem
        return (acc + quantity * volume)
      }, 0)
      .toFixed(2)
    setTotalVolumeFurniture(totalVolumeFurniture)
    const totalVolumeBox = selectedBoxes
      .reduce((acc: any, currentValue: any) => {
        const { quantity, volume } = currentValue
        return (acc + quantity * volume)
      }, 0)
      .toFixed(2)
    setTotalVolumeBox(totalVolumeBox)
  }, [selectedFurniture, selectedBoxes])

  const volume = (Number(totalVolumeBox) || 0) + (Number(totalVolumeFurniture) || 0)
  const totalVolume = volume.toFixed(2)

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>Error: {error?.message || null} </span>
  }
  return (
      <aside className={style.container}>
        <Card>
          <CardContent id="myRooms">
            <h3 className={style.roomsTitle}>{t('general.my_rooms')}</h3>
            {sortedRooms.map((defaultRoom: any) => (
              <DefaultRoom
                onQuantityChange={onQuantityChange(defaultRoom, itemsRef)}
                room={defaultRoom}
                key={defaultRoom.key}
              />
            ))}
            {roomsWithBoxAndFurniture.length > 0 && (
              <>
                <div className={style.furnituresAndBoxTitle}>
                  <h3>{t('general.total_volume')} </h3>
                  {totalVolumeFurniture && totalVolumeBox && <div className={style.volume}>{totalVolume} m³</div>}
                </div>
                {[...roomsWithBoxAndFurniture].reverse().map((roomsWithBoxAndFurniture) => (
                  <div key={roomsWithBoxAndFurniture.id}>
                    <h4 className={style.roomWithFurnitureTitle} id={"labelSidebar" + roomsWithBoxAndFurniture.label}>{roomsWithBoxAndFurniture.label}</h4>
                    <div className={style.selectedFurnitureContainer}>
                      {[...selectedFurniture]
                        .filter((item: SelectedFurniture) => item.room.id === roomsWithBoxAndFurniture.id)
                        .map((selectedFurnitureItem: SelectedFurniture, index: number) => (
                          <SelectedFurnitureItem
                            room={roomsWithBoxAndFurniture}
                            modal={getFurnitureModal}
                            key={index}
                            data={selectedFurnitureItem}
                          />
                        ))}
                    </div>
                    <div className={style.selectedContainer}>
                      {[...selectedBoxes]
                        .filter((item: SelectedBox) => item.room.id === roomsWithBoxAndFurniture.id)
                        .map((selectedBoxItem: SelectedBox, index: number) => (
                          <SelectedBoxItem
                            room={roomsWithBoxAndFurniture}
                            key={index}
                            data={selectedBoxItem}
                        />
                        ))}
                    </div>
                  </div>
                ))}
              </>
            )}
            {boxes.length > 0 && 
              <>
                {boxes[0].quantity != 0 &&
                  <>
                    <div className={style.furnituresAndBoxTitle}>
                      <h3>{t('general.my_boxes')}</h3>
                    </div>
                    {boxes.map((box, index) => (
                      <SelectedBox 
                      key={index} 
                      data={box} 
                      />
                    ))}
                  </>
}
              </>
            }
          </CardContent>
        </Card>
      </aside>
  )
}

export default Sidebar
