import React, { FunctionComponent, ReactElement } from 'react'
import { useDynamicSVGImport } from '../../services/icon'
import { ReactComponent as DefaultFurnitureIcon } from '../../assets/furniture/default.svg'
import { ReactComponent as DefaultBoxesIcon } from '../../assets/boxes/default.svg'

const Icon: FunctionComponent<IconProps> = ({ name, path, ...rest }): ReactElement | null => {
  const { error, loading, SvgIcon } = useDynamicSVGImport(name, path)
  if (error) {
    return path === 'furniture' ? <DefaultFurnitureIcon /> : <DefaultBoxesIcon />
  }
  if (loading) {
    return <div>Loading...</div>
  }
  if (SvgIcon) {
    return <SvgIcon {...rest} />
  }
  return null
}

export default Icon
