import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Popover,
  TextField,
  Button,
  Box,
} from '@mui/material'
import React, { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save'

import style from './SavePopover.module.scss'
import {
  handleChange,
  handleSavePopover,
  handleSavePopoverOpen,
} from './handlers/SavePopoverHandlers'
import { sheetNamePrefix } from './helpers/HeaderHelpers'
import { useSearchParams } from 'react-router-dom'

type SavePopoverProps = {
  setSheetName: React.Dispatch<React.SetStateAction<string | undefined>>
  setSheetNameInput: React.Dispatch<React.SetStateAction<string>>
  sheetName?: string
  sheetNameInput: string
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>
  isSaved: boolean
}

const SavePopover: FunctionComponent<SavePopoverProps> = ({
  setSheetName,
  sheetName,
  setSheetNameInput,
  sheetNameInput,
  setIsSaved,
  isSaved,
}): ReactElement => {
  const { t } = useTranslation()
  const ERROR: ErrorType = {
    WAITING: t('errors.name.waiting'),
    ALREADY_USED: t('errors.name.already_used'),
    NO_ERROR: t('errors.name.no_error'),
  }
  const selectedFurniture = useSelector((state: RootState) => state.selectedFurniture.value)
  const selectedBoxes = useSelector((state: RootState) => state.selectedBoxes.value)
  const selectedRooms = useSelector((state: RootState) => state.selectedRooms.value)
  const sheet = useSelector((state: RootState) => state.sheet.value)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const anchorEl = useRef<HTMLButtonElement>(null)
  const [error, setError] = useState<string>(ERROR.WAITING)

  const stateSetters = {
    setIsOpen,
    setError,
    setSheetName,
    setSheetNameInput,
    setIsSaved,
  }
  const states = { selectedFurniture, selectedBoxes, selectedRooms, sheet, error }

  const [searchParams] = useSearchParams()
  
  let successMessage
  if (searchParams.get('from') != null) {
    successMessage = t('general.snackbar_save')
  } else {
    successMessage = t('general.mobilio_snackbar_save')   
  }
  const errorMessage = t('general.snackbar_save_failed')

  useEffect(() => {
    setSheetNameInput(sheetName ? sheetName : '')
  }, [sheetName])
  useEffect(() => {
    if (!sheetNameInput) {
      setError(ERROR.WAITING)
      if (isSaved) setIsSaved(false)
    } else {
      setError(ERROR.NO_ERROR)
    }
  }, [sheetNameInput])
  
  return (
    <>
      <Button
        color={!error && isSaved ? 'primary' : 'error'}
        variant="contained"
        title={t('general.helper_save_button')}
        onClick={
          isSaved
            ? handleSavePopoverOpen(sheetName, states, stateSetters, dispatch, false, searchParams.get('from'), successMessage, errorMessage)
            : () => setIsOpen(true)
        }
        id="btn-save"
        ref={anchorEl}
      >
        <SaveIcon sx={{ color: '#fff', mr: 1 }} />
        <Box component="span" sx={{ display: { xs: 'none', lg: 'block', color: '#fff' } }}>
          &nbsp;{t('general.save')}
        </Box>
      </Button>
      <Popover
        id="save-popover"
        open={isOpen}
        anchorEl={anchorEl.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FormControl className={style.p24} component="fieldset">
          <FormGroup>
            <FormControlLabel
              className={style.formControlLabel}
              label={sheetNamePrefix + '_'}
              labelPlacement="start"
              control={
                <TextField
                  autoFocus={true}
                  onChange={handleChange(stateSetters)}
                  name="name"
                  placeholder={t('general.add_name')}
                  color="primary"
                  value={sheetNameInput.split('_').slice(1).join('_')}
                  error={!!error}
                  variant="standard"
                  helperText={error}
                />
              }
            />
            <Button
              onClick={handleSavePopover(sheetName, states, stateSetters, dispatch, ERROR, searchParams.get('from'))}
              disabled={!!error}
            >
              {t('general.save')}
            </Button>
          </FormGroup>
        </FormControl>
      </Popover>
    </>
  )
}

export default SavePopover
