import { Button, Popover } from '@mui/material'
import React, { ReactElement, useRef, useState } from 'react'
import 'reactjs-popup/dist/index.css'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useSelector } from 'react-redux'

import styles from './PDFPopover.module.scss'
import PDFLink from './PDFLink'
import { useTranslation } from 'react-i18next'

const PDFPopover = (): ReactElement => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const anchorEl = useRef(null)
  const sheetId = useSelector((state: RootState) => state.sheet.value.id)

  const { t } = useTranslation()

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        className={styles.download}
        onClick={() => setIsPopoverOpen(true)}
        ref={anchorEl}
      >
        <FileDownloadIcon className={styles.downloadIcon}></FileDownloadIcon>
      </Button>
      <Popover
        id="pdf-type-popover"
        open={isPopoverOpen}
        anchorEl={anchorEl.current}
        onClose={() => setIsPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={styles.popover}
      >
        <PDFLink color="success" type="full" sheetId={sheetId}>
          {t`download.full`}
        </PDFLink>
        <PDFLink color="secondary" type="light" sheetId={sheetId}>
          {t`download.light`}
        </PDFLink>
        <PDFLink color="info" type="declaration" sheetId={sheetId}>
          {t`download.declaration`}
        </PDFLink>
      </Popover>
    </>
  )
}

export default PDFPopover
