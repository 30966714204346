import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateSelectedRoomNameById } from '../../../../redux/selectedRoomsReducer'

const Edit: FunctionComponent<RoomActionsProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { room, handleClose } = props

  const dispatch = useDispatch()

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSelectedRoomNameById({
        id: room.id,
        label: event.target.value,
      })
    )
  }

  return (
    <>
      <Input
        sx={{ width: 'initial', marginLeft: '10px', marginRight: '10px' }}
        value={room.label}
        onChange={handleNameChange}
        name={t('general.room')}
        color="primary"
        fullWidth={true}
      />

      <Button variant="outlined" onClick={handleClose} disabled={!room.label}>
        {t('general.finish')}
      </Button>
    </>
  )
}

export default Edit
