import React, { useEffect, useRef, useState } from 'react'
import { DynamicSVGImport, UseDynamicSVGImportOptions } from '../definitions/adapter/icon'

export const useDynamicSVGImport = (
  name: string,
  path: string,
  options: UseDynamicSVGImportOptions = {}
): DynamicSVGImport => {
  const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const { onCompleted, onError } = options
  useEffect(() => {
    setLoading(true)
    const importIcon = async (): Promise<void> => {
      try {
        ImportedIconRef.current = (
          await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../assets/${path}/${name}.svg`)
        ).default
        onCompleted?.(name, ImportedIconRef.current)
      } catch (err: any) {
        onError?.(err)
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    importIcon()
  }, [name, onCompleted, onError])

  return { error, loading, SvgIcon: ImportedIconRef.current }
}
