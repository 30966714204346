import React, { FunctionComponent, ReactElement } from 'react'
import style from './Navigation.module.scss'
import { Button } from '@mui/material'
import { NavigationProps } from '../../definitions/props/navigation'
import { AddCircle } from '@mui/icons-material'

const Navigation: FunctionComponent<NavigationProps> = (props): ReactElement => {
  const { rooms, containerRef } = props

  const handleClick = (index: number, room: any) => {
    const label = (room.label).toString()
    const element = document.getElementById(label)
    const labelSidebar = "labelSidebar" + (room.label).toString()
    const elementSidebar = document.getElementById(labelSidebar)
   
    elementSidebar?.scrollIntoView({ behavior: 'smooth' })
    element?.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToMyRooms = () => {
    const element = document.getElementById('myRooms')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <nav role="navigation" className={style.navigation} ref={containerRef}>
      <ul className={style.menu}>
        {rooms.map((room, index) => (
          <li key={index}>
            <Button color="primary" variant="outlined" onClick={() => handleClick(index, room)}>
              {room.label}
            </Button>
          </li>
        ))}
        <li>
          <Button
            color="primary"
            variant="outlined"
            className={style.add}
            // sx={{ display: { lg: 'none' } }}
            onClick={() => scrollToMyRooms()}
          >
            <AddCircle />
          </Button>
        </li>
      </ul>
    </nav>
  )
}

export default Navigation
