export const SHEETS_URL = `${process.env.REACT_APP_BACK_SHEET}/sheets`

export const SHEETS_GET_ALL = {
  url: SHEETS_URL,
  method: 'GET',
}

export const SHEETS_CREATE = {
  url: `${SHEETS_URL}/:id`,
  method: 'POST',
}

export const SHEETS_UPDATE = {
  url: `${SHEETS_URL}/:id`,
  method: 'PATCH',
}
