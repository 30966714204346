import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextField } from '@mui/material'
import { Comment as CommentIcon } from '@mui/icons-material'
import { updateSelectedFurnitureCommentById } from '../../../../redux/selectedFurnitureReducer'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/system'

const Comment: FunctionComponent<FurnitureActionsProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { furniture, md = 12 } = props

  const dispatch = useDispatch()

  const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSelectedFurnitureCommentById({
        id: furniture.id,
        comment: event.target.value,
      })
    )
  }

  return (
    <>
      <Grid item xs={12} md={md}>
        <Box sx={{ mt: 2 }}>
          <Box sx={{ mb: 1 }}>
            <CommentIcon sx={{ paddingTop: '5px' }}/>{' '}
            {t('general.comment')}
          </Box>
          <Box>
            <TextField
              sx={{ width: '95%%' }}
              value={furniture.furnitureItem.comment}
              onChange={handleCommentChange}
              name={t('general.comment')}
              color="primary"
              placeholder={t('general.comment')}
              fullWidth={true}
              multiline
              rows={3}
            />
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default Comment
