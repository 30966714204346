import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { Policy } from '@mui/icons-material'
import { Grid, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateSelectedFurnitureProtectionById } from '../../../../redux/selectedFurnitureReducer'
import { Box } from '@mui/system'

const Protection: FunctionComponent<FurnitureActionsProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { furniture, md = 3 } = props

  const dispatch = useDispatch()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSelectedFurnitureProtectionById({ id: furniture.id, protection: event.target.value })
    )
  }

  const protectionList = ['cover', 'bubble_wrap', 'bullkraft', 'film', 'mattress_cover']

  return (
    <>
      <Grid item xs={12} md={md} sx={{ pl: 1, pb:1 }}>
        <Box sx={{ pb:2 }}>
          <Policy sx={{ pt: '5px' }}/>{' '}
          {t('general.protection')}
        </Box>
        <Box>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="protection"
              defaultValue=""
              name="protection"
              onChange={handleChange}
            >
              <FormControlLabel
                key="empty"
                value=""
                control={<Radio />}
                label={t(`furniture_actions.protection_empty`)}
                checked={!furniture.actions.protection}
              />
              {protectionList.map((protection) => (
                <FormControlLabel
                  key={protection}
                  value={protection}
                  control={<Radio />}
                  label={t(`furniture_actions.protection_${protection}`)}
                  checked={furniture.actions.protection == protection}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
    </>
  )
}

export default Protection
