import { Warning } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { FunctionComponent, ReactElement } from 'react'
import style from '../../SelectedFurnitureItem.module.scss'
import { useTranslation } from 'react-i18next'

type AttentionIconProps = {
  isAttentionNeeded: boolean
  onClick: () => void
}

const AttentionIcon: FunctionComponent<AttentionIconProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { isAttentionNeeded, onClick } = props
  return (
    <IconButton
      className={style.iconButton}
      color={isAttentionNeeded ? 'primary' : undefined}
      title={t('general.attention')}
      onClick={onClick}
    >
      <Warning />
    </IconButton>
  )
}

export default AttentionIcon
