const volumeCalculatorSheetTransformer = (
  sheetName: string,
  referrer: string | null,
  rooms: SelectedRoom[] = [],
  boxes: SelectedBox[] = [],
  furniture: SelectedFurniture[] = []
): VolumeCalculatorSheet => {
  return {
    name: sheetName,
    referrer : referrer,
    rooms: rooms.map((r): Room => {
      // new room type generation
      return {
        ...r,
        contentBox: [
          ...boxes
            // filter on boxes to find those who attached to the current room
            .filter((b): boolean => b.room.id === r.id)
            // formatting of each filtered boxes to match the new scheme
            .map(({ label, quantity, volume, packing, variant }): Box => {
              return { type: 'box', label, quantity, volume, packing, variant }
            }),
        ],
        contentFurniture: [
          ...furniture
            // filter on furniture to find those who attached to the current room
            .filter((f): boolean => f.room.id === r.id)
            //formatting of each filtered furniture to match the new scheme
            .map(
              ({
                furnitureItem: { key, volume, label, variant, rooms, comment, quantity, monetaryValue },
                actions,
                id,
                variants,
              }): Furniture => {
                return {
                  type: 'furniture',
                  key,
                  id,
                  label,
                  volume,
                  comment,
                  variant,
                  rooms,
                  actions,
                  variants,
                  quantity,
                  monetaryValue,
                }
              }
            ),
        ],
      }
    }),
  }
}

export default volumeCalculatorSheetTransformer
