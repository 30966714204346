import { Help } from '@mui/icons-material'
import { Popover, Box, Typography, Icon } from '@mui/material'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'


import style from './HelperPopover.module.scss'

const HelperPopover = (): ReactElement => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Icon className={style.textHelper} onClick={handleClick}>
        <Help />
      </Icon>
      <Popover
        id={id}
        className={style.modal}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={style.boxHelper}>
          <Typography className={style.textHelper}>{t('general.helper_rooms')}</Typography>
          <Typography className={style.textHelper}>{t('general.helper_items')}</Typography>
          <Typography className={style.textHelper}>{t('general.helper_furniture_characteristic')}</Typography>
          <Typography className={style.textHelper}>{t('general.helper_furniture_actions')}</Typography>
          <Typography className={style.textHelper}>{t('general.helper_boxing')}</Typography>
          <Typography className={style.textHelper}>{t('general.helper_save')}</Typography>
          <Typography className={style.textHelper}>{t('general.helper_download')}</Typography>
          <Typography className={style.textHelper}>{t('general.helper_download_mobilio')}</Typography>
        </Box>
      </Popover>
    </>
  )
}

export default HelperPopover
