import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './Header.module.scss'
import { Box, Button } from '@mui/material'
import SavePopover from './SavePopover'
import { AddCircle } from '@mui/icons-material'
import { handleNewSheet } from './handlers/HeaderHandlers'
import { useDispatch, useSelector } from 'react-redux'
import LoadPopover from './LoadPopover'
import PDFPopover from '../PDF/PDFPopover'
import { HeaderProps } from '../../definitions/props/header'
import { useParams, useSearchParams } from 'react-router-dom'
import { getSheetByName, setSheet } from '../../services/volumeCalculator'
import { loadSheetRooms } from '../../redux/selectedRoomsReducer'
import { loadSheetFurnitures } from '../../redux/selectedFurnitureReducer'
import { loadSheetBoxes } from '../../redux/selectedBoxesReducer'
import { loadSheet, updateSheet } from '../../redux/sheetReducer'
import volumeCalculatorSheetTransformer from '../../services/volumeCalculatorSheetTransformer'
import HelperPopover from './HelperPopover'

const Header: FunctionComponent<HeaderProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { itemsRef } = props

  const [sheetName, setSheetName] = useState<string | undefined>('')
  const [sheetNameInput, setSheetNameInput] = useState('')
  const [isSaved, setIsSaved] = useState(false)

  const sheet = useSelector((state: RootState) => state.sheet.value)
  const dispatch = useDispatch()

  const { idSheet } = useParams()

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (idSheet != undefined && sheetName != undefined) {
      setIsSaved(true)
      setSheetNameInput(sheetName)
      getSheetByName(idSheet)
        .then(async (_sheet: Sheet | undefined) => {
          if (_sheet) {
            return _sheet
          } else {
            
            const saveSheet = await setSheet(
              sheet._id,
              idSheet,
              volumeCalculatorSheetTransformer(idSheet, searchParams.get('from'))
            )
            dispatch(updateSheet(saveSheet))

            return saveSheet as Sheet
          }
        })
        .then((sheet: Sheet) => {
          setSheetName(sheet.name)
          dispatch(loadSheetRooms(sheet))
          dispatch(loadSheetFurnitures(sheet))
          dispatch(loadSheetBoxes(sheet))
          dispatch(loadSheet(sheet))
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {})
    }
  }, [idSheet])

  return (
    <header className={style.header} role="banner">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
        }}
      >
        <Box sx={{ p: 1 }}>
          <h1 className={style.title}>{t('general.site_title')}</h1>
        </Box>
        <Box sx={{ p: 1, display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
          <h2 className={style.title}>{sheetName && <>{sheetName}</>}</h2>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            flexWrap: 'wrap',
            bgcolor: 'background.paper',
          }}
        >
          {!sheet.name && (
          <Box sx={{ p: 1 }}>
            <Button onClick={handleNewSheet(dispatch, setSheetName, itemsRef)} variant="outlined">
              <AddCircle />
              <Box component="span" sx={{ display: { xs: 'none', lg: 'block' } }}>
                &nbsp;{t('general.create')}
              </Box>
            </Button>
          </Box>
          )}
          {!idSheet &&
            (<Box sx={{ p: 1 }}>
              <LoadPopover
                setSheetName={setSheetName}
                setSheetNameInput={setSheetNameInput}
                setIsSaved={setIsSaved}
              />
            </Box>)}
          <Box sx={{ p: 1 }}>
            <SavePopover
              setSheetName={setSheetName}
              sheetName={sheetName}
              setSheetNameInput={setSheetNameInput}
              sheetNameInput={sheetNameInput}
              setIsSaved={setIsSaved}
              isSaved={isSaved}
            />
          </Box>
          {sheet.name && (
            <Box sx={{ p: 1 }}>
              <PDFPopover />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{ marginLeft: 3.32, display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' } }}
      >
      </Box>
      <HelperPopover />
    </header>
  )
}

export default Header
