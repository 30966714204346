import React, { FunctionComponent, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import style from '../SelectedFurnitureItem/SelectedFurnitureItem.module.scss'
import { useDispatch } from 'react-redux'
import { addBox, removeBox } from '../../redux/selectedBoxesReducer'
import { DocumentContent } from 'kuzzle-sdk'
import { Remove, Add } from '@mui/icons-material'
import { IconButton } from '@mui/material'

const SelectedBoxItem: FunctionComponent<SelectedBoxItemProps> = (
  props
): ReactElement => {
  const { data } = props
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()
  const handleIncreaseBox = useCallback((box: SelectedBox, room: DocumentContent) => {
    dispatch(addBox({ box, room }))
  }, [])
  const handleDecreaseBox = useCallback((box: SelectedBox, room: DocumentContent) => {
    dispatch(removeBox({ box, room }))
  }, [])

  return (
   <div key={data.id}>
    {data.quantity > 0 &&
      <div className={style.boxContainer}  >
        <div className={style.header}>
          <div className={style.box}>
            {i18n.exists(`boxes.${data.label}`) ? t(`boxes.${data.label}`) : data.label}
            <IconButton onClick={() => handleDecreaseBox(data, data.room)}>
              <Remove />
            </IconButton>
              {' '}{data.quantity}{' '}
            <IconButton onClick={() => handleIncreaseBox(data, data.room)}>
              <Add />
            </IconButton>
          </div>
        </div>
      </div>
    }
  </div>
  )
}

export default SelectedBoxItem
