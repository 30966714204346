import { useTranslation } from 'react-i18next'

// Translate items passed as arguments, return a sorted translated list of item
export const translateItems = (items: Item[], i18nLabel: string) => {
  const { t } = useTranslation()

  // display items with i18n
  const sortedItemsList = items.sort((a, b) =>
    t(`${i18nLabel}.${a.label}`).localeCompare(t(`${i18nLabel}.${b.label}`))
  )
  const itemLabelsList = sortedItemsList.map((item) => t(`${i18nLabel}.${item.label}`))
  return sortedItemsList.map((item, index) => {
    return { ...item, label: itemLabelsList[index], type: i18nLabel }
  })
}
