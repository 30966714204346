import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import frLocale from './i18n/fr.json'
import enLocale from './i18n/en.json'
import deLocale from './i18n/de.json'
import esLocale from './i18n/es.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  fr: { translation: frLocale },
  en: { translation: enLocale },
  es: { translation: esLocale },
  de: { translation: deLocale },
}

const setLocale = (locale = 'en') => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: locale,
      fallbackLng: 'en',
      debug: true,
    })
}

i18n.use(initReactI18next) // passes i18n down to react-i18next

export { setLocale, i18n }
