import React, { ReactElement } from 'react'
import { createTheme, ThemeProvider } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import store from '../../redux/store'
import Main from '../Main/Main'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import CustomSnackbarProvider from '../CustomProvider/CustomSnackbarProvider'


const App = (): ReactElement => {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#90C5B9',
        main: '#6bb2a2',
        dark: '#50857A',
      },
    },
    // Tuning MUI breakpoints with ours
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1400,
      },
    },
  })

  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider>
          <Provider store={store}>
            <CustomSnackbarProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<Main />}>
                    <Route path="/:locale" element={<Main />}>
                      <Route path=":idSheet" element={<Main />} />
                    </Route>
                  </Route>
                </Routes>
              </Router>
            </CustomSnackbarProvider>
          </Provider>
        </StyledEngineProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
