import { Build } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { FunctionComponent, ReactElement } from 'react'
import style from '../../SelectedFurnitureItem.module.scss'
import { useTranslation } from 'react-i18next'

const DisassemblyIcon: FunctionComponent<DisassemblyIconProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { isDisassembled, onClick } = props
  return (
    <IconButton
      className={style.iconButton}
      color={isDisassembled ? 'primary' : undefined}
      title={t('general.disassembly')}
      onClick={onClick}
    >
      <Build />
    </IconButton>
  )
}

export default DisassemblyIcon
