import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import style from './Room.module.scss'
import { useTranslation } from 'react-i18next'
import Furniture from '../Furniture/Furniture'
import Box from '../Box/Box'
import CustomFurniture from '../CustomFurniture/CustomFurniture'
import { RoomProps } from '../../definitions/props/room'
import EditItIcon from './Actions/Edit/EditIcon'
import EditName from './Actions/Edit/EditName'
import { useQuery } from 'react-query'
import { BOXES_GET_ALL, FURNITURES_GET_ALL, serviceClient } from '../../api'

const Room: FunctionComponent<RoomProps> = (props) => {
  const { room, itemsRef, navigationContainerRef } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const [refElement, setRefElement] = useState<HTMLDivElement | null>(null)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const {
    isLoading: isLoadingBoxes,
    isError: isErrorBoxes,
    data: boxes,
    error: errorBoxes,
  }: { isLoading: boolean; isError: boolean; data: any; error: any } = useQuery(
    [`DEFAULT_BOXES_${room.key}`],
    {
      queryFn: () => serviceClient(BOXES_GET_ALL),
    }
  )

  const {
    isLoading: isLoadingFurniture,
    isError: isErrorFurniture,
    data: furnitures,
    error: errorFurniture,
  }: { isLoading: boolean; isError: boolean; data: any; error: any } = useQuery(
    [`DEFAULT_FURNITURE_${room.key}`],
    {
      queryFn: () => serviceClient(FURNITURES_GET_ALL),
    }
  )

  useEffect(() => {
    if (refElement && navigationContainerRef && navigationContainerRef.current) {
      window.scrollTo({
        top:
          refElement.getBoundingClientRect().top +
          window.pageYOffset -
          navigationContainerRef.current.clientHeight,
        behavior: 'smooth',
      })
    }
  }, [refElement, navigationContainerRef])

  const EditMemo = useMemo(() => <EditItIcon onClick={handleOpen} />, [])

  if (isLoadingBoxes || isLoadingFurniture) {
    return <span>Loading...</span>
  }

  if (isErrorBoxes) {
    return <span>Error: {errorBoxes?.message || null} </span>
  }
  if (isErrorFurniture) {
    return <span>Error: {errorFurniture?.message || null} </span>
  }
  return (
    <div>
      <div id={room.id}
        className={style.room}
        ref={(element) => {
          if (element && !itemsRef.current.includes(element)) {
            itemsRef.current.push(element)
            setRefElement(element)
          }
        }}
      >
        {!open && (
          <h2 id={room.label}>
            {room.label} {EditMemo}
          </h2>
        )}
        {open && <EditName room={room} handleClose={handleClose} />}
        <div className="container-fluid">
          <div className="row row-full">
            <CustomFurniture furniture={furnitures} room={room} boxes={boxes} />
            {furnitures
              .filter((item: any) => {
                const roomNames = item.rooms.split(',')
                return roomNames.includes(room.key)
              })
              .sort((a: any, b: any) =>
                t(`furniture.${a.label}`).localeCompare(t(`furniture.${b.label}`))
              )
              .map((item: any, index: number) => (
                <Furniture key={item.key + index} furniture={item} room={room} />
              ))}
            {boxes.length &&
              boxes
                .filter((item: any) => {
                  const roomNames = item.rooms.split(',')
                  return roomNames.includes(room.key)
                })
                .map((item: any) => <Box key={item.key} box={item} room={room} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Room
