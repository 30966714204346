import React, { FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import style from '../SelectedFurnitureItem.module.scss'
import LeaveIt from './LeaveIt/LeaveIt'
import Protection from './Protection/Protection'
import Disassembly from './Disassembly/Disassembly'
import Attention from './Attention/Attention'
import EditVolume from './Edit/EditVolume'
import EditVariant from './Edit/EditVariant'
import { AppBar, Dialog, IconButton, Toolbar, Typography, Box, DialogActions, Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import { getRoomLabelNumber } from '../../../utils/getRoomLabel'
import Comment from './Comment/Comment'
import MonetaryValue from './MonetaryValue/MonetaryValue'

type ActionModalProps = {
  furniture: SelectedFurniture
  open: boolean
  onClose: () => void
}

const ActionModal: FunctionComponent<ActionModalProps> = (props): ReactElement => {
  const { furniture, open, onClose } = props

  const colSize = 2

  const { t } = useTranslation()
  
  return (
    <>
      <Dialog sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "70em",  // Set your width here
        },
      },
    }} open={open} onClose={onClose}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {`${t(`rooms.${furniture.room.key}`, {
                defaultValue: furniture.room.key,
              })} ${getRoomLabelNumber(furniture.room.label)}`}{' '}
              &gt;{' '}
              {t(`furniture.${furniture.furnitureItem.label}`, {
                defaultValue: furniture.furnitureItem.label,
              })}{' '}
              {furniture.furnitureItem.variant &&
                t(`furniture.variants.${furniture.furnitureItem.variant}`)}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          className={style.modal}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'start',
            alignItems: 'baseline',
            p: 2,
          }}
        >
          <Box sx={{ p: 2, mb: 2 }}>
            <EditVolume furniture={furniture} md={12} />
            <MonetaryValue furniture={furniture} md={12} />
          </Box>
          <Comment furniture={furniture} md={8} />
          <EditVariant furniture={furniture} md={3} />
          <Disassembly furniture={furniture} md={3} />
          <Attention furniture={furniture} md={colSize} />
          <Protection furniture={furniture} md={colSize} />
          <LeaveIt furniture={furniture} md={colSize} />
          <Box className="helper">
            <Typography>{t('general.edit_message')}</Typography>
          </Box>
        </Box>

        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>{t('general.save')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ActionModal
