import React, { useState, FunctionComponent, ReactElement, useCallback, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import EditIcon from '../SelectedFurnitureItem/Actions/Edit/EditIcon'
import RedeemIcon from '@mui/icons-material/Redeem'
import style from '../SelectedFurnitureItem/SelectedFurnitureItem.module.scss'
import { useDispatch } from 'react-redux'
import { updateSelectedBoxPacking } from '../../redux/selectedBoxesReducer'
import { Close } from '@mui/icons-material'
import { AppBar, Box, Dialog, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Toolbar, Typography } from '@mui/material'

const packingOptions = ['by_client', 'by_mover']

const SelectedBoxItem: FunctionComponent<SelectedBoxProps> = (
  props
): ReactElement => {
  const { data } = props
  const [ selectedBox, setSelectedBox ] = useState(data)
  const [ packing, setPacking ] = useState(selectedBox.packing)
  const { t, i18n } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])
  const dispatch = useDispatch()

  const handlePackingChange = (event: ChangeEvent<HTMLInputElement>) => {
    const packing = event.target.value
    setPacking(packing)
    const updatedSelectedBox = { ...selectedBox, packing: packing }
    setSelectedBox(updatedSelectedBox)
    dispatch(updateSelectedBoxPacking({ label: selectedBox.label, packing: packing }))
  }

  return (
   <div key={data.id}>
    {data.quantity > 0 &&
      <div className={style.boxContainer}  >
        <div className={style.header}>
            {data.quantity}{' x '}
            {i18n.exists(`boxes.${data.label}`) ? t(`boxes.${data.label}`) : data.label}
            <EditIcon onClick={handleOpen} />
            <Dialog open={open} onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Close />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {`${t(`rooms.${selectedBox.room.key}`, {
                defaultValue: selectedBox.room.key,
                })} `}
                &gt;{' '}{t(`boxes.${selectedBox.label}`, {
                defaultValue: selectedBox.label,
                })}{' '}
              </Typography>
              </Toolbar>
            </AppBar>
              <Box
                className={style.modal}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'start',
                  alignItems: 'baseline',
                  p: 2,
                }}
              >
                <Grid item xs={12}>
                <Box sx={{ mb: 2 }}>
                  <Box>
                    {t('boxes.actions.packing')}
                  </Box>
                <Box>
                <FormControl component="fieldset">
                  <RadioGroup
                  aria-label="packing"
                  value={packing}
                  onChange={handlePackingChange}
                  >
                    {packingOptions.map((option) => (
                      <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={t(`boxes.actions.${option}`)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                </Box>
              </Box>
            </Grid>
              </Box>
            </Dialog>
            {data.quantity > 0 && (
            <>
            <span className={`${style.volumeBox}`} >
                {(data.quantity * data.volume).toFixed(2)} m<sup>3</sup>
            </span>
            </>
            )}
        </div>
        {selectedBox.packing == 'by_mover' && (
          <div className={style.actionBox}>
              <RedeemIcon />
          </div>
        )}
      </div>
    }
  </div>
  )
}

export default SelectedBoxItem
