import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, FormControlLabel, FormGroup, Grid, Input } from '@mui/material'
import EuroIcon from '@mui/icons-material/Euro'
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound'
import { updateSelectedFurnitureMonetaryValueById } from '../../../../redux/selectedFurnitureReducer'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/system'
import { useParams } from 'react-router-dom'

const MonetaryValue: FunctionComponent<MonetaryValueProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const { furniture, md = 12 } = props

  const dispatch = useDispatch()
  const { locale } = useParams()

  const currency = locale === "en" ? "£" : "€"

  const handleMonetaryValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
        updateSelectedFurnitureMonetaryValueById({
        id: furniture.id,
        monetary_value: event.target.value,
        currency
      })
    )
  }

  return (
    <>
      <Grid item xs={12} md={md}>
        <Box sx={{ mt: 2 }}>
          <Box sx={{ mb: 1 }}>
          {locale === "en" ? <CurrencyPoundIcon sx={{ paddingTop: '5px' }}/> : <EuroIcon sx={{ paddingTop: '5px' }}/>}
            {' '}
            {t('general.monetary_value')}
          </Box>
          <Box>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Input
                      type="number"
                      sx={{ width: '80%', marginLeft: '10px' }}
                      defaultValue={furniture.furnitureItem.monetaryValue}
                      onChange={handleMonetaryValueChange}
                      name={t('general.monetary_value')}
                      color="primary"
                      fullWidth={true}
                    />
                  }
                  label={currency}
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default MonetaryValue
