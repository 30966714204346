import { Dispatch } from '@reduxjs/toolkit'
import { loadSheetBoxes } from '../../../redux/selectedBoxesReducer'
import { loadSheetFurnitures } from '../../../redux/selectedFurnitureReducer'
import { loadSheetRooms } from '../../../redux/selectedRoomsReducer'
import { updateSheet } from '../../../redux/sheetReducer'
import { getSheets } from '../../../services/volumeCalculator'

interface StateSetters {
  setAvailableSheets: React.Dispatch<React.SetStateAction<Sheet[]>>
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsAvailableSheetsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setIsSheetLoading: React.Dispatch<React.SetStateAction<boolean>>
  setSheetName: React.Dispatch<React.SetStateAction<string | undefined>>
  setSheetNameInput: React.Dispatch<React.SetStateAction<string>>
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>
}

export const handleLoadPopoverOpen =
  ({ setAvailableSheets, setIsOpen, setIsAvailableSheetsLoading }: StateSetters) =>
  async (): Promise<void> => {
    setIsAvailableSheetsLoading(true)
    const sheets = await getSheets()
    if (sheets) {
      setAvailableSheets(sheets)
    }
    setIsAvailableSheetsLoading(false)
    setIsOpen(true)
  }
export const handleLoadPopoverClose =
  ({ setIsOpen }: StateSetters) =>
  (): void => {
    setIsOpen(false)
  }

export const handleSheetLoad =
  (
    { setIsSheetLoading, setSheetName, setIsOpen, setIsSaved }: StateSetters,
    dispatch: Dispatch<any>
  ) =>
  (event: React.SyntheticEvent, value: Sheet | null): void => {
    if (value) {
      setIsSheetLoading(true)
      dispatch(updateSheet(value))
      setSheetName(value.name)
      dispatch(loadSheetRooms(value))
      dispatch(loadSheetFurnitures(value))
      dispatch(loadSheetBoxes(value))
      setIsSheetLoading(false)
      setIsOpen(false)
      setIsSaved(true)
    }
  }
