import React, { FunctionComponent, ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import style from './SelectedFurnitureItem.module.scss'
import Delete from './Actions/Delete/Delete'
import Quantity from './Actions/Quantity/Quantity'
import AttentionIcon from './Actions/Attention/AttentionIcon'
import DisassemblyIcon from './Actions/Disassembly/DisassemblyIcon'
import ProtectionIcon from './Actions/Protection/ProtectionIcon'
import LeaveItIcon from './Actions/LeaveIt/LeaveItIcon'
import EditIcon from './Actions/Edit/EditIcon'
import { useParams } from 'react-router-dom'

const SelectedFurnitureItem: FunctionComponent<SelectedFurnitureItemProps> = (
  props
): ReactElement => {
  const { data, modal } = props

  const { id, furnitureItem, actions } = data
  const { t, i18n } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const AttentionMemo = useMemo(
    () =>
      actions.attention.length > 0 && (
        <AttentionIcon isAttentionNeeded={actions.attention.length > 0} onClick={handleOpen} />
      ),
    [actions.attention]
  )
  const DisassemblyMemo = useMemo(
    () =>
      actions.disassembly.toDisassemble && (
        <DisassemblyIcon isDisassembled={actions.disassembly.toDisassemble} onClick={handleOpen} />
      ),
    [actions.disassembly.toDisassemble]
  )
  const ProtectionMemo = useMemo(
    () =>
      !!actions.protection && (
        <ProtectionIcon isProtectionNeeded={!!actions.protection} onClick={handleOpen} />
      ),
    [actions.protection]
  )
  const LeaveItMemo = useMemo(
    () =>
      !!actions.leaveIt && <LeaveItIcon isLeftBehind={!!actions.leaveIt} onClick={handleOpen} />,
    [actions.leaveIt]
  )

  const DeleteItMemo = useMemo(() => <Delete id={id} />, [id])
  const QuantityMemo = useMemo(() => <Quantity id={id} />, [id, furnitureItem])
  const EditMemo = useMemo(() => <EditIcon onClick={handleOpen} />, [])

  const { locale } = useParams()

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h5 className={style.title}>
          {i18n.exists(`furniture.${furnitureItem.label}`)
            ? t(`furniture.${furnitureItem.label}`)
            : furnitureItem.label}
          {furnitureItem.variant && (
            <span className={style.variant}>
              {i18n.exists(`furniture.variants.${furnitureItem.variant}`)
                ? t(`furniture.variants.${furnitureItem.variant}`)
                : furnitureItem.variant}
            </span>
          )}
          {EditMemo}
        </h5>
        {QuantityMemo}
        <span
          className={`${style.volume} ${actions.leaveIt === 'is_left' ? style.linethrough : ''}`}
        >
          {furnitureItem.volume} m<sup>3</sup>
          {furnitureItem.monetaryValue && (
            <span style={{ marginLeft: "15px" }}>
              {`${furnitureItem.monetaryValue} ${locale === "en" ? "£" : "€"}`} 
            </span>
          )}
        </span>
        {DeleteItMemo}
      </div>
      <div>
        {AttentionMemo}
        {DisassemblyMemo}
        {ProtectionMemo}
        {LeaveItMemo}
      </div>
      {open && modal(props.data, open, handleClose)}
    </div>
  )
}

export default SelectedFurnitureItem
